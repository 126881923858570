import React from "react";

import { Link } from "gatsby";

import type { Node } from "@/types";

import { Author } from "./Author";
import { Comments } from "./Comments";
import { Content } from "./Content";
import { Meta } from "./Meta";
import { Tags } from "./Tags";
import { Gallery } from "./Gallery";

import { GalleryData } from "../../types";

import * as styles from "./Post.module.scss";

interface Props {
  post: Node;
  gallery: GalleryData;
}

const Post: React.FC<Props> = ({ post, gallery }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date } = post.frontmatter;

  let galleryNode = null;
  if (gallery.totalCount > 0) {
    galleryNode = (
      <Gallery gallery={gallery} />
    );
  }

  return (
    <div className={styles.post}>
      <Link className={styles.button} to="/">
        All Articles
      </Link>

      <div className={styles.content}>
        <Content body={html} title={title} />
        {galleryNode}
      </div>

      <div className={styles.footer}>
        <Meta date={date} />
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        <Author />
      </div>

      <div className={styles.comments}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export default Post;
