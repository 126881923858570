import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { GalleryData } from "../../../types";

import * as styles from "./Gallery.module.scss";

type Props = {
  gallery: GalleryData
};

const Gallery = ({ gallery }: Props) => {
  const images = [];
  for (const [index, node] of gallery.nodes.entries()) {
    const imgKey = `gallery-img-${index}`;
    const sharp = node.childrenImageSharp[0];
    if (sharp) {
      const image = sharp?.gatsbyImageData;
      if (image) {
        images.push(
            <GatsbyImage key={imgKey} alt={imgKey} image={image} />
        );
      }
    }
  }

  if (images.length > 0) {
    return (
        <div className={styles.gallery}>
          {images}
        </div>
    );
  }
  return null;
};

export default Gallery;
